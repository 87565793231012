export const disabledClass = '--disabled';
export async function fetchForm(form, url, data, callbackSuccess = () => {}, callbackError = () => {}) {
    form.classList.add(disabledClass);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const { result } = await response.json();
        callbackSuccess(form, result);
    } catch (error) {
        console.error(error);
        callbackError();
    } finally {
        form.classList.remove(disabledClass);
    }
}
