import HystModal from 'hystmodal';
import { fetchForm } from './fetchForm';
import { isValidEmail } from './isValidEmail';

export const initContactUsModalForm = () => {
    // VARS
    const form = document.querySelector('[data-contact-us-form]');
    if (!form) return;
    const url = form.getAttribute('action');
    const fields = form.querySelectorAll('input:not([type="hidden"]), textarea');
    const errorClass = '--has-error';

    // INITS
    const contactUsModalForm = new HystModal({
        linkAttributeName: 'data-hystmodal',
        catchFocus: false,
        afterClose: function (modal) {
            form.querySelectorAll(`.${errorClass}`).forEach((field) => {
                field.classList.remove(errorClass);
            });
        },
    });

    // LISTENERS
    form.addEventListener('submit', handleSubmit);
    fields.forEach((field) => {
        field.addEventListener('focus', () => {
            field.classList.remove(errorClass);
        });
    });

    // HANDLERS
    async function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(form);

        if (isValidForm(formData)) {
            await fetchForm(form, url, formData, callbackSuccess);
        }
    }

    // FUNCTIONS
    function isValidForm(formData) {
        let isValidForm = true;

        if (!formData.get('name').trim()) {
            form.querySelector('[name="name"]').classList.add(errorClass);
            isValidForm = false;
        }

        if (!formData.get('email').trim() || !isValidEmail(formData.get('email').trim())) {
            form.querySelector('[name="email"]').classList.add(errorClass);
            isValidForm = false;
        }

        if (!formData.get('text').trim()) {
            form.querySelector('[name="text"]').classList.add(errorClass);
            isValidForm = false;
        }

        return isValidForm;
    }
    function callbackSuccess() {
        contactUsModalForm.open('[data-contact-us="success-send"]');
        resetFields();
    }

    function resetFields() {
        fields.forEach((field) => {
            field.value = '';
        });
    }
};
