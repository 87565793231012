import { document } from 'mixpanel-browser/src/utils';
import { scrollToElem } from '../../../../../Common/Resources/src_front/js/helper-functions/scrollToElem';

export const initScrollToAnchor = () => {
    document.querySelectorAll('[data-anchor-link]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            const targetSelector = this.getAttribute('data-anchor-link');
            const target = document.querySelector(`[data-anchor-block="${targetSelector}"]`);

            if (target) {
                e.preventDefault();
                closeMobileMenu();
                scrollToElem(`[data-anchor-block="${targetSelector}"]`, -70);
            }
        });
    });

    function closeMobileMenu() {
        if (window.innerWidth < 1024) {
            document.body.classList.remove('mobile-menu-is-open');
        }
    }
};
