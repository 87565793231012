import Swiper from 'swiper/bundle';

export const initCTASlider = () => {
    new Swiper('[data-cta-slider]', {
        loop: false,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        spaceBetween: 20,
        navigation: {
            nextEl: '[data-cta-slider-next]',
            prevEl: '[data-cta-slider-prev]',
        },
    });
};
