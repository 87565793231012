import { initCTASlider } from '../components/sliders/initCTASlider';
import { initAccordion } from '../components/initAccordion';
import { initScrollToAnchor } from '../components/initScrollToAnchor';
import { initContactUsModalForm } from '../components/forms/initContactUsModalForm';

document.addEventListener('DOMContentLoaded', handleOnLoadPage);

function handleOnLoadPage() {
    initCTASlider();
    initAccordion();
    initScrollToAnchor();
    initContactUsModalForm();
}
